const acronyms: string[] = [
  'ADT',
  'IP',
  'ED',
  'SNF',
  'HHA',
  'IA',
  'PHN',
  'ACO',
  'BCBS',
  'MD',
  'DO',
  'NP',
  'NPS',
  'ACAH',
];
const camelCased = { archcare: 'ArchCare', centerlight: 'CenterLight', carenu: 'CareNu' };
/**
 * Takes strings in underscore and mixed case
 * and converts them to human-readable strings,
 * leaving any acronyms as is.
 * It also handles words known to be in CamelCase
 *
 * @param {string} str - a string in underscore or mixed case
 * @returns {string} human readable string
 */
export const titleCase = (str: string = ''): string => {
  try {
    return str
      ?.split(/[_ ]+/)
      .map(word => {
        if (camelCased[word.toLowerCase()]) {
          return camelCased[word.toLowerCase()];
        } else if (acronyms.includes(word.toUpperCase())) {
          return word.toUpperCase();
        } else {
          return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
        }
      })
      .join(' ');
  } catch (e) {
    console.error(e);
    return str;
  }
};

/**
 * Takes strings in underscore and mixed case
 * and converts them to All Caps With Spaces,
 * e.g. "THIS_IS_A_STRING" => "THIS IS A STRING"
 * or "this_is_a_string" => "THIS IS A STRING"
 * @param {string} str
 * @returns {string} all caps with spaces
 */
export const allCapsWithSpaces = (str: string = ''): string => {
  try {
    return str
      ?.split(/[_ ]+/)
      .map(word => word.toUpperCase())
      .join(' ');
  } catch (e) {
    console.error(e);
    return str;
  }
};

/**
 * Check if a string only contains numbers.
 * @param {string} str - the string to check
 * @returns {boolean} - the result
 */
export const containsOnlyNumbers = (str: string = ''): boolean => /^\d+$/.test(str);
